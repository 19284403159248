import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "TIL from Twitch stream with @cassiecodes about SVG animation",
  "path": "/til/from-twitch-stream-with-@cassiecodes-about-SVG-animation",
  "date": "2020-07-26T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Video link: `}<a parentName="p" {...{
        "href": "https://www.twitch.tv/videos/687415585"
      }}>{`https://www.twitch.tv/videos/687415585`}</a><br parentName="p"></br>{`
`}{`Codepen link: `}<a parentName="p" {...{
        "href": "https://codepen.io/cassie-codes/pen/bGEzMMK"
      }}>{`https://codepen.io/cassie-codes/pen/bGEzMMK`}</a></p>
    <ol>
      <li parentName="ol">{`Add `}<inlineCode parentName="li">{`title`}</inlineCode>{` inside SVG and `}<inlineCode parentName="li">{`aria-labelledby`}</inlineCode>{` for accessibility.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://colorbox.io"
        }}>{`Colorbox.io`}</a>{` tools by guys on Lyft.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://github.com/chrisgannon/ScrubGSAPTimeline"
        }}>{`Scrub GSAP Timeline`}</a>{` to debug GSAP Timeline.`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`stagger`}</inlineCode>{` property to multi-target tween basically offsets the starting time of each target’s animation. More detail `}<a parentName="li" {...{
          "href": "https://greensock.com/docs/v3/Staggers"
        }}>{`here`}</a>{`.`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`random(between A, to B)`}</inlineCode>{` also a cool utility method from GSAP.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      